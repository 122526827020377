import { titleCase } from 'title-case'
import { format } from 'date-fns'

export function toTitleCase (str: string | undefined) {
  if (!str) { return '' }
  return titleCase(str, {
    sentenceCase: true
  })
}

export async function copyPassword (str: string | undefined) {
  return await navigator.clipboard.writeText(str || '')
}

export function dateFormat (date: string | Date | undefined, pattern: string = 'dd-MMM-yyyy') {
  if (!date) { return '' }
  return format(new Date(date), pattern)
}
