<template>
  <div class="fixed bottom-0 right-0 px-3">
    <div class="flex flex-col items-end">
      <p class="text-sm dark:text-white">
        {{ timeStr }}
      </p>
      <p class="text-base dark:text-white">
        {{ dateStr }}
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
const timeStr = ref('hh:mm AM')
const dateStr = ref('DD-MMM-YYYY')

let timeInterval = undefined as undefined | ReturnType<typeof loadDate>

function loadDate () {
  return setInterval(() => {
    const date = new Date()
    timeStr.value = dateFormat(date, 'hh:mm:ss a')
    dateStr.value = dateFormat(date, 'dd-MMMM-yyyy')
  }, 1000)
}

onMounted(() => {
  if (process.client) {
    timeInterval = loadDate()
  }
})

onUnmounted(() => {
  if (process.client) {
    clearInterval(timeInterval)
  }
})
</script>
