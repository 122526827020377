import { default as _91slug_93a9rdwG7zOFMeta } from "/usr/src/ros-udom-blog/pages/blogs/[slug].vue?macro=true";
import { default as indexelvKD6cbmfMeta } from "/usr/src/ros-udom-blog/pages/blogs/index.vue?macro=true";
import { default as index9UzMe2Of0SMeta } from "/usr/src/ros-udom-blog/pages/index.vue?macro=true";
import { default as _91slug_9357a7z7emiFMeta } from "/usr/src/ros-udom-blog/pages/tags/[slug].vue?macro=true";
import { default as indexpPk0VQruM9Meta } from "/usr/src/ros-udom-blog/pages/tags/index.vue?macro=true";
export default [
  {
    name: _91slug_93a9rdwG7zOFMeta?.name ?? "blogs-slug",
    path: _91slug_93a9rdwG7zOFMeta?.path ?? "/blogs/:slug()",
    meta: _91slug_93a9rdwG7zOFMeta || {},
    alias: _91slug_93a9rdwG7zOFMeta?.alias || [],
    redirect: _91slug_93a9rdwG7zOFMeta?.redirect,
    component: () => import("/usr/src/ros-udom-blog/pages/blogs/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexelvKD6cbmfMeta?.name ?? "blogs",
    path: indexelvKD6cbmfMeta?.path ?? "/blogs",
    meta: indexelvKD6cbmfMeta || {},
    alias: indexelvKD6cbmfMeta?.alias || [],
    redirect: indexelvKD6cbmfMeta?.redirect,
    component: () => import("/usr/src/ros-udom-blog/pages/blogs/index.vue").then(m => m.default || m)
  },
  {
    name: index9UzMe2Of0SMeta?.name ?? "index",
    path: index9UzMe2Of0SMeta?.path ?? "/",
    meta: index9UzMe2Of0SMeta || {},
    alias: index9UzMe2Of0SMeta?.alias || [],
    redirect: index9UzMe2Of0SMeta?.redirect,
    component: () => import("/usr/src/ros-udom-blog/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9357a7z7emiFMeta?.name ?? "tags-slug",
    path: _91slug_9357a7z7emiFMeta?.path ?? "/tags/:slug()",
    meta: _91slug_9357a7z7emiFMeta || {},
    alias: _91slug_9357a7z7emiFMeta?.alias || [],
    redirect: _91slug_9357a7z7emiFMeta?.redirect,
    component: () => import("/usr/src/ros-udom-blog/pages/tags/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexpPk0VQruM9Meta?.name ?? "tags",
    path: indexpPk0VQruM9Meta?.path ?? "/tags",
    meta: indexpPk0VQruM9Meta || {},
    alias: indexpPk0VQruM9Meta?.alias || [],
    redirect: indexpPk0VQruM9Meta?.redirect,
    component: () => import("/usr/src/ros-udom-blog/pages/tags/index.vue").then(m => m.default || m)
  }
]