<template>
  <NuxtLayout>
    <NuxtPage />
    <Toast />
    <BaseDigitalClock />
  </NuxtLayout>
</template>

<script setup lang="ts">
useHead({
  title: 'Ros Udom - Full Stack Developer | Resume',
  // add charset meta tags
  meta: [
    {
      name: 'Ros Udom - Full Stack Developer | Resume',
      content: 'IE=edge',
      'http-equiv': 'X-UA-Compatible'
    },
    {
      name: 'msapplication-TileColor',
      content: '#da532c'
    },
    {
      name: 'theme-color',
      content: '#ffffff'
    },
    {
      name: 'author',
      content: 'Ros Udom'
    }
  ],
  htmlAttrs: {
    lang: 'en'
  },
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: '/base/icon/favicon.ico'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/base/icon/apple-touch-icon.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/base/icon/favicon-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/base/icon/favicon-16x16.png'
    },
    {
      rel: 'manifest',
      href: '/base/icon/site.webmanifest'
    },
    {
      rel: 'mask-icon',
      href: '/base/icon/safari-pinned-tab.svg',
      color: '#5bbad5'
    },
    {
      rel: 'preconnect',
      href: 'https://fonts.googleapis.com'
    },
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com'
    },
    {
      href: 'https://fonts.googleapis.com/css2?family=Noto+Sans+Khmer:wght@100..900&display=swap',
      rel: 'stylesheet'
    }
  ],
  bodyAttrs: {
    class: 'dark:bg-gray-700'
  }
})

useSeoMeta({
  title: 'Ros Udom - Full Stack Developer | Resume',
  description: 'Ros Udom - Full Stack Developer from Codeclans',
  ogDescription: 'Ros Udom - Full Stack Developer from Codeclans',
  ogImage: 'https://ros-udom.tech/base/profile/me.webp',
  author: 'Ros Udom',
  generator: 'Ros Udom',
  viewport: 'width=device-width, initial-scale=1, shrink-to-fit=no',
  ogLocale: 'km_KH',
  ogType: 'website',
  ogUrl: 'https://ros-udom.tech',
  ogSiteName: 'Ros Udom',
  ogTitle: 'Ros Udom - Full Stack Developer | Resume'
})

onMounted(() => {
  if (process.client) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    console.log(`██████   ██████  ███████     ██    ██ ██████   ██████  ███    ███
██   ██ ██    ██ ██          ██    ██ ██   ██ ██    ██ ████  ████
██████  ██    ██ ███████     ██    ██ ██   ██ ██    ██ ██ ████ ██
██   ██ ██    ██      ██     ██    ██ ██   ██ ██    ██ ██  ██  ██
██   ██  ██████  ███████      ██████  ██████   ██████  ██      ██ `)
    console.log('Welcome to my blogs. I hope you enjoy it.')
    console.log('This website is built with Nuxt.js, Tailwind CSS, and Vite.')
    console.log('😁🙌😂')
  }
})

</script>
